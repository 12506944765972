<template>
  <a-spin :spinning="page_loading">
    <sdPageHeader title="Hediye Kredi Yükle (Cargong)">
      <template #buttons>
        <div class="page-header-actions" />
      </template>
    </sdPageHeader>
    <Main>
      <sdCards title="Kredi Yükleme Formu">
        <a-form
          layout="vertical"
        >
          <a-form-item label="Müşteri Seçiniz">
            <a-select
              v-model:value="form_state.customer_ids"
              mode="multiple"
              placeholder="Müşteri Seçiniz"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in customers"
                :key="item.id"
              >
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="Paket Seçiniz"
          >
            <a-radio-group
              v-for="item in this.packages"
              v-model:value="form_state.package_id"
              size="large"
              default-value="item"
            >
              <a-radio-button :value="item.id">
                {{ item.credit }}
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            name="Not"
            label="Not"
          >
            <a-textarea
              v-model:value="form_state.note"
              :rows="4"
              placeholder="Not Giriniz"
            />
          </a-form-item>
          <sdButton
            size="small"
            type="primary"
            @click="handleAddCredit"
          >
            <sdFeatherIcons
              type="save"
              size="14"
            />
            <span>Kaydet</span>
          </sdButton>
        </a-form>
      </sdCards>
    </Main>
  </a-spin>
</template>

<script>

import { Main } from '../styled'

export default {
  name: 'CarongAddCredit',
  components: {
    Main,
  },
  data() {
    return {
      page_loading: false,
      form_submiting: false,

      customers: [],
      packages: [],

      form_state: {
        customer_ids: [],
        package_id: null,
        note: null,
      },
    }
  },
  mounted() {
    this.page_loading = true
    this.$restMethods.postForNoc('/cargong-add-credit-open').then(response => {
      if ( ! response.hasError()) {
        const data = response.getData()
        this.customers = data.customers
        this.packages = data.packages
      }
      this.page_loading = false
    })
  },
  methods: {
    handleAddCredit() {
      this.form_submiting = true
      this.$restMethods.postForNoc('/cargong-add-credit-submit', this.form_state, { show_success: true }).then(response => {
        this.form_submiting = false
      })
    },
  },
}
</script>
